import { Component, Injector, ViewChild } from '@angular/core';
import { LoaderUtils } from '@bv/ionic';
import { StringUtils } from '@bv/angular-commons';
import { map, Observable } from "rxjs";
import { ZigConstants } from '../../../constants/zig';
import { FieldDefinitions } from '../../../models/field-definition';
import { ZigService } from '../../../providers/services/business/zig.service';
import { TranslateService } from '@ngx-translate/core';
import { BasePageComponent } from '../../base-page-component';
import { ZigFilterModel } from '../zig-filter/zig-filter.component';
import { IonModal } from '@ionic/angular';
import { MessageService } from "../../../providers/core/message.service";
import { BvAlertController } from '@bv/ionic';

@Component({
    selector: 'page-zig-doc-export',
    templateUrl: 'zig-doc-export.component.html'
  })
  export class ZigDocExportComponent extends BasePageComponent {
    
    //injections
    private readonly alertCtrl = this.injector.get<BvAlertController>(BvAlertController);
    public readonly zigSrv = this.injector.get<ZigService>(ZigService);
    private readonly messageSrv = this.injector.get(MessageService);
    private readonly translateSrv = this.injector.get<TranslateService>(TranslateService);

    @ViewChild(IonModal) modal!: IonModal;
    
    public model: ZigFilterModel = new ZigFilterModel();
    public invoicing_years: Observable<String[]> | undefined = undefined;

    public readonly NONEVALUES = {
      STRING: 'NONE',
      NUMBER: -9999
    };

    constructor(protected injector: Injector) {
      super(injector, 'ZigDocExportComponent');
    }

    ngOnInit(): void {
      const invoicing_years = this.zigSrv.getExportFilterData(ZigConstants.FIELDS.INVOICING_DATE);
      this.init(invoicing_years);
      this.model.invoicingDate = this.NONEVALUES.STRING;
    }

    private init(invoicing_years: Observable<any[]>): void {
        this.invoicing_years = invoicing_years.pipe(map((arr: string[]) => [this.NONEVALUES.STRING, ...arr]));
    }

    protected load(filter: ZigFilterModel): void {
      this.model = filter;
    }
    
    public onChange(event: string, field: string): void {
      if (event === this.NONEVALUES.STRING) {
          this.logger.debug('onChange' + field, event);
          (this.model as any)[field] = undefined;
      }
    }
      
    cancelModal() {
      this.modal.dismiss(null, 'cancel');
    }

    public exportFolderToZip() {
      if (this.filterIsEmpty()) {
        this.alertCtrl
            .create({
                header: this.translateSrv.instant('common.filter.nofilters.alert.title'),
                message: this.translateSrv.instant('zig.exportzip.filter.nofilters.alert.message'),
                buttons: [
                    {
                        text: this.translateSrv.instant('common.filter.nofilters.alert.button'),
                        cssClass: 'primary'
                    }
                ]
            });
      } else {
        this.modal.dismiss(null, 'cancel');
        LoaderUtils.presentLoading(this.injector).then(loading => {
          let obs: Observable<string> | undefined = undefined;
          const rootPath = this.model.companyNumber + "/" + this.model.invoicingDate;
          obs = this.zigSrv.zipFolderPathAndDownload(rootPath);
          if (obs) {
            obs.subscribe((response: string) => {
              // Stop loading
              loading.dismiss();
              this.modal.present();
            },
            (error: any) => {
              this.logger.error('Error during export', error);
              const errorCode = JSON.parse(JSON.stringify(error)).error.errorcode;
              if (!StringUtils.isBlank(errorCode)) {
                this.messageSrv.showError({ messageKey: "common.exportzip." + errorCode });
              } else {
                this.messageSrv.showError({ messageKey: "common.exportzip.failed" });
              }
              // Stop loading
              loading.dismiss();
            });
          }
        });
      }
    }

    private filterIsEmpty(): boolean {
      return (StringUtils.isBlank(this.model.companyNumber) 
          || StringUtils.isBlank(this.model.invoicingDate)
          || this.model.invoicingDate === this.NONEVALUES.STRING);
    }

    protected getFields(): FieldDefinitions {
      return ZigConstants.FIELDS_ZIG;
    }
}