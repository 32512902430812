import { BaseMetadataComponent } from "../../base-metadata-component";
import { Component, Injector, Inject, LOCALE_ID } from "@angular/core";
import { FieldDefinitions } from "../../../models/field-definition";
import { Observable } from "rxjs";
import { ModalController } from "@ionic/angular";
import { ZigConstants } from "../../../constants/zig";
import { ZigService } from "../../../providers/services/business/zig.service";
import { ZigZipModel } from "../../../models/zig/zig-export-list.model";

@Component({
    selector: "page-zig-metadata-export",
    templateUrl: "zig-metadata-export.component.html"
})
export class ZigMetadataExportComponent extends BaseMetadataComponent {
    constructor(
        protected injector: Injector,
        @Inject(LOCALE_ID) public locale: string
    ) {
        super(injector, "ZigMetadataExportComponent");
    }

    //injections
    private readonly viewCtrl =
        this.injector.get<ModalController>(ModalController);
    private readonly zigSrv = this.injector.get<ZigService>(ZigService);

    // Public
    public model?: ZigZipModel;
    public showMore = false;

    protected getFields(): FieldDefinitions {
        return ZigConstants.FIELDS_ZIG_EXPORT;
    }

    protected init(): void {}

    public onClickClose(): void {
        this.viewCtrl.dismiss();
    }

    public onClickLink(): void {
        if (!this.model) {
            throw new Error("Download could not be done: no model");
        }
        let obs: Observable<string> | undefined = undefined;
        obs = this.zigSrv.downloadZip(this.model);
        if (obs) {
            obs.subscribe((response: string) => {
                this.logger.debug("Download url", response);
                console.log(response);
                window.open(JSON.parse(JSON.stringify(response)).url);
            });
        }
    }
}
