import { Injectable, Injector } from "@angular/core";
import { map, Observable } from "rxjs";
import { ZigFilterModel } from "../../../components/zig/zig-filter/zig-filter.component";
import { WebServices } from "../../../constants/webservices";
import { ApiResult } from "../../../models/api-result";
import { ZigDocumentModel } from "../../../models/zig/zig-doc-document.model";
import { HttpService } from "../../core/http/http.service";
import { BaseService } from "../base-service";
import { ApiService } from "../tech/api.service";
import { AutocompleteInterfaceService } from "./autocomplete.service";
import { ZigZipModel } from "src/app/models/zig/zig-export-list.model";

@Injectable()
export class ZigService
	extends BaseService
	implements AutocompleteInterfaceService
{
	//Injections
	private readonly httpSrv = this.injector.get<HttpService>(HttpService);
	private readonly apiSrv = this.injector.get<ApiService>(ApiService);

	//constructor
	constructor(protected readonly injector: Injector) {
		super(injector, "ZigService");
	}

	public getFilterData(field: string): Observable<(string | number)[]> {
		return this.httpSrv
			.call(
				WebServices.parametrize(WebServices.ZIG.GET_FILTERDATA, field)
			)
			.pipe(
				map((res: ApiResult<ZigDocumentModel>) =>
					this.apiSrv.extractAggregation(res)
				)
			);
	}

	public getExportFilterData(field: string): Observable<(string | number)[]> {
		return this.httpSrv
			.call( 
				WebServices.parametrize(WebServices.ZIG.GET_EXPORT_FILTERDATA, field)
			)
			.pipe(
				map((res: ApiResult<ZigDocumentModel>) =>
					this.apiSrv.extractAsStringAggregation(res)
				)
			);
	}

	public getAll(
		filters: ZigFilterModel
	): Observable<ApiResult<ZigDocumentModel>> {
		return this.httpSrv.call(
			WebServices.parametrize(WebServices.ZIG.GET_ALL, filters)
		);
	}

	public download(model: ZigDocumentModel): Observable<string> | undefined {
		return this.httpSrv.call(
			WebServices.parametrize(WebServices.ZIG.GET_DOWNLOAD, model)
		);
	}

	public getAutocompleteData(
		field: string,
		value: any
	): Observable<(string | number)[]> {
		return this.httpSrv
			.call(
				WebServices.parametrize(
					WebServices.ZIG.GET_AUTOCOMPLETEDATA,
					field,
					value
				)
			)
			.pipe(
				map((res: ApiResult<any>) =>
					this.apiSrv.extractAggregation(res)
				)
			);
	}

	public apiResultToModels(
		res: ApiResult<ZigDocumentModel>
	): ZigDocumentModel[] {
		return res.hits.hits.map((hit) => {
			const res = hit._source;
			res.id = hit._id;
			return res;
		});
	}

	public zipResultsAndDownload(keys: string[]): Observable<string> | undefined {
		return this.httpSrv.call(
			WebServices.parametrize(WebServices.ZIG.POST_ZIPRESULTS_DOWNLOAD, keys)
		);
	}
	public zipFolderPathAndDownload(keysPath: string): Observable<string> | undefined {
		return this.httpSrv.call(
			WebServices.parametrize(WebServices.ZIG.GET_ZIPPATH_DOWNLOAD, keysPath)
		);
	}

	public getZipFilesGenerated(): Observable<any[]> {
		return this.httpSrv.call(WebServices.ZIG.GET_ZIPFILES_GENERATED);
	}

	public downloadZip(model: ZigZipModel): Observable<string> | undefined {
		return this.httpSrv.call(
			WebServices.parametrize(WebServices.ZIG.GET_ZIP_DOWNLOAD, model)
		);
	}
}
